const TAGS_CONSTANT = {
    cookingType: 22144,
    occasion: 22145,
    special: 22146,
}

const DIFFICULTIES_CONSTANT = {
    EASY: 22148,
    MEDIUM: 22149,
    HARD: 22150,
}

const PORTFOLIO_SORT_CONSTANT = 26309
const RECIPE_CLASSIFICATION_SORT_CONSTANT = 22162

const mondelezAPI = {
    brandID: 26028,
    siteID: "royalpt2023",
    websiteID: 153,
    apiLocale: "ptpt",
    languageID: 2,
    sectorID: "20234",
    classificationList: [
        {
            type: "cookingType",
            id: TAGS_CONSTANT.cookingType,
        },
        {
            type: "occasion",
            id: TAGS_CONSTANT.occasion,
        },
        {
            type: "special",
            id: TAGS_CONSTANT.special,
        },
    ],
    timeSpan: [
        {
            type: "prepTime",
            value: 275,
        },
        {
            type: "totalTime",
            value: 271,
        },
    ],
}

module.exports = {
    mondelezAPI,
    TAGS_CONSTANT,
    DIFFICULTIES_CONSTANT,
    PORTFOLIO_SORT_CONSTANT,
    RECIPE_CLASSIFICATION_SORT_CONSTANT,
}
