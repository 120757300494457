import React from "react"
import PropTypes from "prop-types"

import SubscriptionFormSection from "./SubscriptionFormSection/SubscriptionFormSection"
import SubscriptionFormImage from "./SubscriptionFormImage/SubscriptionFormImage"
import * as styles from "./SubscriptionForm-tw-styles"
import SubscriptionFormBanner from "./SubscriptionFormBanner/SubscriptionFormBanner"

const SubscriptionForm = props => {
    const {
        active,
        heading,
        description,
        image,
        newsletterConfirmation,
        thirdPartyConfirmation,
        sectionId,
        bannerTitle,
        bannerDescription,
        bannerPrimaryCtaLabel,
        bannerSecondaryCtaLabel,
    } = props
    const style = styles.defaultStyle
    if (!active) return <></>
    return (
        <>
            <div className={style.wrapper} id={sectionId ?? null}>
                <div className={style.contentWrapper}>
                    <div className={style.titleSection}>
                        <h2 className={style.heading}>{heading}</h2>
                        <p className={style.description}>{description}</p>
                    </div>
                    <div className={style.content}>
                        <SubscriptionFormImage image={image} />
                        <SubscriptionFormSection
                            newsletterConfirmation={newsletterConfirmation}
                            thirdPartyConfirmation={thirdPartyConfirmation}
                        />
                    </div>
                </div>
            </div>
            <SubscriptionFormBanner
                title={bannerTitle}
                description={bannerDescription}
                primaryCtaLabel={bannerPrimaryCtaLabel}
                secondaryCtaLabel={bannerSecondaryCtaLabel}
                sectionId={sectionId}
            />
        </>
    )
}

SubscriptionForm.propTypes = {
    active: PropTypes.bool,
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    newsletterConfirmation: PropTypes.string,
    thirdPartyConfirmation: PropTypes.string,
    sectionId: PropTypes.string,
    style: PropTypes.object,
    bannerTitle: PropTypes.string,
    bannerDescription: PropTypes.string,
    bannerPrimaryCtaLabel: PropTypes.string,
    bannerSecondaryCtaLabel: PropTypes.string,
}

export default SubscriptionForm
